/* Timesheet.css or similar */
.timesheetcontainer {
    display: flex;
    flex-direction: row;
  }
  
  .timesheet {
    flex: 1;
    padding: 10px;
  }
  
.headoftimesheet{
    text-align: center;
}
.timesheet-input-td{
  width: 51%;
}
.timesheet-form-control{
  box-sizing: border-box !important;
}
.print{
  display:none;
}
@media print {
  .no-print{
    display: none;
  } 
.print{
  display: block;
}
}