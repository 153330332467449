.task-badges-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .task-badge {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #198754;
    color: white;
    border-radius: 5px;
  }
  
  .task-badge .btn {
    padding: 0 5px;
    margin-left: 5px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  