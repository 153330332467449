.admin-dashboard {
  display: flex;
  min-height: 100vh;
  position: relative; /* Ensure the layout works correctly */
}

.sidebar {
  width: 250px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* position: fixed; Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
  overflow-y: scroll; /* Allow scrolling for the sidebar */
}

/* Hide scrollbar for WebKit browsers */
.sidebar::-webkit-scrollbar {
  width: 0; /* Hide vertical scrollbar */
  background: transparent; /* Optional: add a transparent background */
}
.sidebar.collapsed {
  width: 60px;
}

.logo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.toggle-btn {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  padding: 10px;
  border-radius: 50%;
}

.toggle-btn:hover {
  background-color: #ddd; /* or any color you prefer for the hover effect */
  color: #333; /* or any color you prefer for the hover effect */
}

.search-section {
  width: 100%;
  padding: 10px;
}

.search-section input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.nav {
  flex: 1;
  width: 100%;
  padding: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: #f1f1f1;
}

.nav-item.active {
  background-color: #198754;
  color: #fff;
}

.nav-item span {
  margin-left: 10px;
}

.bottom-section {
  width: 100%;
  padding: 10px;
}

.bottom-section .nav-item {
  display: flex;
  align-items: center;
}

.content-area {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  /* margin-left: 250px; Offset for the fixed sidebar */
  height: 100vh; /* Ensure it takes the full height */
  overflow-y: auto; /* Allow scrolling within the content area */
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }
}
.colourlink{
  color: black;
  text-decoration: none;
}

.top-navbar {
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #198754;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the navbar stays on top */
}

.top-navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.admin-welcome {
  font-size: 1.2em;
}

.logout-btn {
  background-color: #fff;
  color: #198754;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logout-btn:hover {
  background-color: #f8f9fa;
  color: #198754;
}
.nav-heading {
  font-weight: bold;
  margin: 5px 0 5px 5px; /* Adjusted for uniform spacing */
  color: #198754;
}
