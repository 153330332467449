.mainscreenadmin{
    display: flex;
}
.para-heading {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.inputdiv{
    margin-bottom: 5px;
    /* font-size: 15px; */
}
.formcontainer{
    /* margin: 25px auto; */
    margin:auto;
    box-shadow: 0 0 10px #00000026;
    padding: 35px;
}
/* Styling for non-active tabs */
.nav-link {
    color: black !important; /* Ensure non-active tabs are black */
}

/* Styling for the active tab */
.nav-link.active {
    color: #198754 !important; /* Ensure active tab is green */
    font-weight: bold;
}
.nav-itemmm{
    padding: 4px;
}
