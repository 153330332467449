.description-tooltip {
    position: relative;
    display: inline;
  }
  
  .info-icon {
    margin-left: 8px;
    cursor: pointer;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #6c757d;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .description-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  